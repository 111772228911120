import { ALL_ROLES, EXTERNAL, INTERNAL, INTERNAL_ADMINS } from "../../lib";
import {
  NotAuthorizedPage,
  NotFoundPage,
  NotificationsPage,
  QuickLinksPage,
  SearchPage,
  SessionPage,
  SessionsPage,
  ProfilePage,
} from "./shared";
import {
  UsersPage,
  TasksPage,
  OrganizationsPage,
  InternalSettingsPage,
  OrganizationPage,
  ConsultantDashboard,
  ReportsPage,
  ReportPage,
  SessionAndTaskPage,
  TimerPage,
  TimerReviewPage,
  BillingPage,
  AdminDashboard,
  AdminTimerPage,
  MigrationPage,
} from "./internal";
import {
  AccountSettingPage,
  ExternalTasksPage,
  PreScreenPage,
  HomePage,
  MediaPage,
} from "./external";

const overlapPages = (isInternal: boolean, isAdmin?: boolean) => {
  return {
    home: {
      anon: false,
      path: "/",
      title: "Dashboard",
      view: isInternal
        ? isAdmin
          ? AdminDashboard
          : ConsultantDashboard
        : HomePage,
      roles: isInternal ? INTERNAL : EXTERNAL,
    },
    tasks: {
      anon: false,
      path: "/tasks",
      title: "Tasks",
      view: isInternal ? TasksPage : ExternalTasksPage,
      roles: isInternal ? INTERNAL : EXTERNAL,
    },
    sessions: {
      anon: false,
      path: "/sessions",
      title: isInternal ? "Sessions and Tasks" : "Sessions",
      view: isInternal ? SessionAndTaskPage : SessionsPage,
      roles: isInternal ? INTERNAL : EXTERNAL,
    },
    settings: {
      anon: false,
      path: "/settings",
      title: "Settings",
      view: isInternal ? InternalSettingsPage : AccountSettingPage,
      roles: isInternal ? INTERNAL : EXTERNAL,
    },
  };
};

export const InternalPages = {
  organizations: {
    anon: false,
    path: "/organizations",
    title: "Organizations",
    view: OrganizationsPage,
    roles: INTERNAL,
  },
  organization: {
    anon: false,
    path: "/organizations/:id",
    title: "Organization",
    view: OrganizationPage,
    roles: INTERNAL,
  },
  users: {
    anon: false,
    path: "/users",
    title: "Users",
    view: UsersPage,
    roles: INTERNAL,
  },
  report: {
    anon: false,
    path: "/reports/:id",
    title: "Report",
    view: ReportPage,
    roles: INTERNAL,
  },
  reports: {
    anon: false,
    path: "/reports",
    title: "Reports",
    view: ReportsPage,
    roles: INTERNAL,
  },
  timer: {
    anon: false,
    path: "/timer",
    title: "Timer",
    view: TimerPage,
    roles: INTERNAL,
  },
  adminTimer: {
    anon: false,
    path: "/timer/admin", // do admins need both routes?
    title: "Timer entries",
    view: AdminTimerPage,
    roles: INTERNAL_ADMINS,
  },
  timerReview: {
    anon: false,
    path: "/timer/review",
    title: "Timer review",
    view: TimerReviewPage,
    roles: INTERNAL,
  },
  billing: {
    anon: false,
    path: "/billing",
    title: "Billing",
    view: BillingPage,
    roles: INTERNAL_ADMINS,
  },
  migrations: {
    anon: false,
    path: "/migrations",
    title: "Migrations",
    view: MigrationPage,
    roles: INTERNAL_ADMINS,
  },
};

export const MainPages = (isInternal: boolean, isAdmin?: boolean) => {
  const overlaps = overlapPages(isInternal, isAdmin);
  return {
    ...overlaps,
    ...InternalPages,
    prescreen: {
      anon: false,
      path: "/select-org",
      title: "Select Which Org",
      view: PreScreenPage,
      roles: EXTERNAL,
    },
    session: {
      anon: false,
      path: "/sessions/:id",
      title: "Sessions",
      view: SessionPage,
      roles: ALL_ROLES,
    },
    search: {
      anon: false,
      path: "/search",
      title: "Search",
      view: SearchPage,
      roles: ALL_ROLES,
    },
    media: {
      anon: false,
      path: "/documents",
      title: "Documents",
      view: MediaPage,
      roles: EXTERNAL,
    },
    notifications: {
      anon: false,
      path: "/notifications",
      title: "Notifications",
      view: NotificationsPage,
      roles: ALL_ROLES,
    },
    quicklinks: {
      anon: false,
      path: "/quicklinks",
      title: "Quicklinks",
      view: QuickLinksPage,
      roles: EXTERNAL,
    },
    profile: {
      anon: false,
      path: "/profile",
      title: "My Profile",
      view: ProfilePage,
      roles: ALL_ROLES,
    },
    notAuthorized: {
      anon: true,
      path: "unauthorized",
      title: "Not Authorized",
      view: NotAuthorizedPage,
      roles: ALL_ROLES,
    },
    notFound: {
      anon: true,
      path: "*",
      title: "Page Not Found",
      view: NotFoundPage,
      roles: ALL_ROLES,
    },
  } as const;
};
