import {
  GridRowGroupingModel,
  GridRowSelectionModel,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import { useDatagridProps, useQuery } from "../hooks";
import { StyledDataGridPremium } from "./DataGrid.styles";
import { useMobile } from "../../themes";
import { useAdminTimesColumns } from "../../columns";
import {
  GetTimeSpentOnAllOrgsApiArg,
  useBulkDeleteTimersMutation,
  useGetMyTimeSpentOnAllOrgsQuery,
  useGetDetailedTimeSpentOnAllOrgsQuery,
} from "../../state/rtk-query/state/timer";
import { CardStyled } from "../styled";
import { useCallback, useMemo, useState } from "react";
import { DateRange } from "@mui/x-date-pickers-pro";
import { capitalizeSentence, filterBoolean, useLocation } from "../../lib";
import { Stack } from "@mui/material";
import RowsSelectedBanner from "../cards/RowsSelectedBanner";
import { useRowSelectionModel } from "../hooks/useRowSelectionModel";
import { ConfirmDeleteDialog } from "./ConfirmDeleteDialog";
import { useGridStateQuery } from "../hooks/useFilterModel";
import { TimerTableToolbar } from "./TimerTableToolbar";
import { useDispatch, useSelector } from "react-redux";
import { authSelectors, uiActions } from "../../state";
import { endOfDay, startOfDay } from "date-fns";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";

type FilterType = GetTimeSpentOnAllOrgsApiArg["filterTime"];

interface QueryProps {
  dateRange?: DateRange<any> | undefined;
  time_filter?: FilterType;
  month?: string;
  org_name?: string;
  consultant?: string;
}

export const TimerTable = ({ adminPage = false }: { adminPage?: boolean }) => {
  const isMobile = useMobile();
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const location = useLocation();

  const [deleteTimers] = useBulkDeleteTimersMutation();
  const [query] = useQuery<QueryProps>("dateRange", "time_filter");
  const isAdmin = useSelector(authSelectors.isAdmin);
  const isInternal = useSelector(authSelectors.isInternal);
  const columns = useAdminTimesColumns(isMobile, isAdmin);
  const { slotProps, autosizeOptions } = useDatagridProps();
  const { time_filter, dateRange } = query;
  const hasDateRange = useMemo(
    () => dateRange && !!filterBoolean(dateRange)?.length,
    [dateRange],
  );

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ["org_name"] as GridRowGroupingModel,
      },
      aggregation: {
        model: {
          time_spent: "sum",
        },
      },
    },
  });

  const formatGroupByName = useCallback((models?: GridRowGroupingModel) => {
    return capitalizeSentence(
      (models ?? [])
        .map((m) => (m === "org_name" ? "Organization" : m.replace("_", " ")))
        .join(", "),
    );
  }, []);

  const [groupByName, setGroupByName] = useState(
    formatGroupByName(initialState?.rowGrouping?.model) ?? "",
  );
  const tz = useMemo(
    () => Intl.DateTimeFormat().resolvedOptions().timeZone,
    [],
  );
  const todaysRange = useMemo(
    () => [startOfDay(new Date()), endOfDay(new Date())] as DateRange<any>,
    [],
  );

  const consultantHook = useGetMyTimeSpentOnAllOrgsQuery(
    isInternal
      ? {
          dateRange: hasDateRange ? dateRange : todaysRange,
          tz,
        }
      : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const adminHook = useGetDetailedTimeSpentOnAllOrgsQuery(
    isAdmin && adminPage
      ? {
          dateRange: hasDateRange ? dateRange : undefined,
          filterTime: time_filter,
          tz,
        }
      : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const { currentData } = isAdmin && adminPage ? adminHook : consultantHook;
  const { onCellClick, onColumnHeaderClick, selectedRows, setSelectedRows } =
    useRowSelectionModel(currentData);
  const changeName = useCallback(
    (models: GridRowGroupingModel) => {
      setGroupByName(capitalizeSentence(formatGroupByName(models)));
    },
    [formatGroupByName],
  );
  const confirmDelete = useCallback(async () => {
    await deleteTimers({ body: { timers: selectedRows } });
    setSelectedRows([]);
  }, [deleteTimers, selectedRows, setSelectedRows]);

  const {
    filterModel,
    paginationModel,
    setFilterModel,
    setPaginationModel,
    setSortModel,
    sortModel,
  } = useGridStateQuery();

  React.useEffect(() => {
    if (location.query?.org_name) {
      //Need to wait for the data to come back for the filter to take effect
      setTimeout(() => {
        apiRef.current?.setFilterModel({
          items: [
            {
              field: "org_name",
              operator: "equals",
              value: location.query?.org_name,
            },
            {
              field: "status",
              operator: "isAnyOf",
              value: ["reviewed", "new"],
            },
            {
              field: "timer_type",
              operator: "is",
              value: "CW - Billable",
            },
          ],
        });
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.query?.org_name]);

  const handleSelectionModelChange = useCallback(
    (model: GridRowSelectionModel) => {
      if (!model.length) {
        dispatch(
          uiActions.showWarning(
            "Please expand at least one row, to apply multi select",
          ),
        );
        setSelectedRows([]);
      } else {
        setSelectedRows(model as any[]);
      }
    },
    [dispatch, setSelectedRows],
  );

  const Table = useMemo(() => {
    return (
      <StyledDataGridPremium
        rows={currentData ?? []}
        filterModel={filterModel}
        apiRef={apiRef}
        columns={columns}
        disableRowSelectionOnClick
        initialState={initialState}
        autosizeOnMount={true}
        autosizeOptions={autosizeOptions}
        slotProps={{ ...slotProps, toolbar: {} }}
        groupingColDef={{
          flex: isMobile ? undefined : 1,
          headerName: `Group by "${groupByName}"`,
        }}
        sx={{
          ".MuiDataGrid-virtualScroller": {
            height: "100%",
          },
        }}
        onCellClick={onCellClick}
        onRowGroupingModelChange={changeName}
        pagination
        onFilterModelChange={setFilterModel}
        onColumnHeaderClick={onColumnHeaderClick}
        onRowSelectionModelChange={handleSelectionModelChange}
        checkboxSelection={true}
        isRowSelectable={(p) => {
          return p.row.time_spent !== undefined;
        }}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        slots={{
          toolbar: TimerTableToolbar,
        }}
      />
    );
  }, [
    currentData,
    filterModel,
    apiRef,
    columns,
    initialState,
    autosizeOptions,
    slotProps,
    isMobile,
    groupByName,
    onCellClick,
    changeName,
    setFilterModel,
    onColumnHeaderClick,
    selectedRows,
    sortModel,
    setSortModel,
    paginationModel,
    setPaginationModel,
  ]);

  return (
    <>
      <RowsSelectedBanner
        selectedRowsCt={selectedRows.length}
        onClear={() => setSelectedRows([])}
        onSelectAll={() => setSelectedRows(currentData?.map((r) => r.id) || [])}
      >
        <ConfirmDeleteDialog
          buttonText="Delete timers"
          buttonColor="info"
          handleConfirm={confirmDelete}
          titleText="Delete timers"
          subTitleText="Are you sure you want to delete these timers?"
        />
      </RowsSelectedBanner>
      <CardStyled
        sx={{
          height: "100%",
          mb: 2,
          mt: selectedRows.length > 0 ? (adminPage ? -2 : -1) : 0,
        }}
      >
        <Stack height={"100%"} overflow={"hidden"}>
          {Table}
        </Stack>
      </CardStyled>
    </>
  );
};
