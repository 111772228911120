import {
  CardContentContainerStyled,
  CardContentItemStyled,
  CardStyled,
} from "../styled";
import { useMobile } from "../../themes";
import { DisplayUsage } from "./DisplayUsage";

interface Props {
  includedCW: number;
  hoursUsed: number;
  hoursAvailable: number;
  hoursBilled: number;
  hoursUnBilled: number;
  usedIncludedCW: number;
}

export const CWUsageCard = ({
  includedCW,
  hoursUsed,
  hoursAvailable,
  hoursBilled,
  hoursUnBilled,
  usedIncludedCW,
}: Props) => {
  const isMobile = useMobile();

  return (
    <CardStyled
      sx={{
        my: 1,
        overflow: "auto",
      }}
    >
      <CardContentContainerStyled container minHeight={"100%"}>
        <CardContentItemStyled
          item
          display={"flex"}
          justifyContent={isMobile ? "flex-start" : "space-between"}
          px={2}
          py={isMobile ? 1 : 2}
          alignItems={"flex-start"}
          flexDirection={isMobile ? "column" : "row"}
          gap={1}
          height={"100%"}
          minHeight={"100%"}
        >
          <DisplayUsage count={includedCW} title="Included CW" />
          <DisplayUsage count={usedIncludedCW} title="Included CW used" />
          <DisplayUsage count={hoursUsed} title="Time used" />
          <DisplayUsage count={hoursAvailable} title="Included CW Available" />

          <DisplayUsage count={hoursBilled} title={"Billed"} />
          <DisplayUsage count={hoursUnBilled} title={"Unbilled"} />
        </CardContentItemStyled>
      </CardContentContainerStyled>
    </CardStyled>
  );
};
